<template>
  <section class="promo-code-info">
    <div class="promo-code-info__users">
      <div v-if="shouldShowPromoCodeField" class="field">
        <span class="field__label">For</span>
        <base-select
          :value="promoCodeUsers"
          :items="$options.promoCodeUsersSelectOptions"
          :disabled="!shouldShowPromoCodeField"
          class="promo-code-users__select"
          @input="updatePromoCodeField($event, { fieldName: 'doctorRestricted' })"
        />
      </div>

      <div v-if="shouldShowDoctorField" class="field">
        <span class="field__label">Doctor</span>
        <v-autocomplete
          :value="currentDoctor"
          :items="foundDoctorsComputed"
          :disabled="!shouldShowPromoCodeField"
          :rules="[$options.validateDoctorSearchInput]"
          :search-input.sync="searchDoctorName"
          :item-text="selectFoundItemText"
          item-value="id"
          hide-selected
          outlined
          dense
          return-object
          placeholder="Enter doctor name"
          append-icon="mdi-magnify"
          @input="updateDoctorField"
        >
          <template #selection="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
      </div>
    </div>
    <div class="promo-code-info__code-creation d-flex justify-space-between align-start">
      <div class="field">
        <span class="field__label">Code</span>
        <base-text-field
          class="field__input"
          :value="value.code"
          :disabled="isEditMode"
          :error-messages="codeErrors.code"
          :rules="[$options.validationRules.REQUIRED]"
          @input="updatePromoCodeField($event, { fieldName: 'code' })"
        />
      </div>
      <div class="generate-button-wrapper">
        <v-btn
          v-if="!isEditMode"
          class="white--text ml-3"
          tile
          large
          color="light-blue darken-4"
          min-width="170"
          @click="generateCode"
        >
          Generate
        </v-btn>
      </div>
    </div>

    <div class="promo-code-info__amount">
      <div class="field">
        <span class="field__label">Amount</span>
        <base-text-field
          class="field__input"
          :value="value.amount"
          :disabled="isEditMode"
          :error-messages="codeErrors.amount"
          :rules="[$options.validationRules.REQUIRED]"
          @input="updatePromoCodeField($event, { fieldName: 'amount' })"
        />
      </div>

      <div class="field">
        <span class="field__label">Currency or %</span>
        <base-select
          class="field__input"
          :value="promoCodeCurrency"
          :items="$options.amountTypeSelectOptions"
          :disabled="isEditMode"
          @input="updatePromoCodeCurrencyField($event, { fieldName: 'currency' })"
        />
      </div>
    </div>

    <div class="promo-code-info__validity">
      <div class="field">
        <span class="field__label">Start date</span>
        <v-menu transition="scale-transition" offset-y :nudge-bottom="10" min-width="200px">
          <template v-slot:activator="{ on }">
            <v-text-field
              append-icon="mdi-calendar-check"
              readonly
              outlined
              dense
              hide-details
              background-color="white"
              class="field__input"
              :value="formatDate(value.startDate)"
              :disabled="isStartDateFieldDisabled"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="value.startDate"
            :min="$options.minStartDate"
            :max="getMaxStartDate(value.expirationDate)"
            no-title
            @change="updatePromoCodeField($event, { fieldName: 'startDate' })"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="field">
        <span class="field__label">End date</span>
        <v-menu transition="scale-transition" offset-y :nudge-bottom="10" min-width="200px">
          <template v-slot:activator="{ on }">
            <v-text-field
              append-icon="mdi-calendar-check"
              readonly
              outlined
              dense
              hide-details
              background-color="white"
              class="field__input"
              :value="formatDate(value.expirationDate)"
              :disabled="!shouldShowPromoCodeField"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="value.expirationDate"
            :min="getMinExpirationDate"
            no-title
            @change="updatePromoCodeField($event, { fieldName: 'expirationDate' })"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>
    <div class="field">
      <span class="field__label">Patient location</span>
      <base-select
        :value="selectedCountries"
        v-bind="$options.countriesConfig"
        multiple
        :disabled="!shouldShowPromoCodeField"
        @input="updateCountryField($event, { fieldName: 'availableCountriesList' })"
      />
    </div>
  </section>
</template>

<script>
import { addDays, subDays } from 'date-fns';
import { last } from 'ramda';

import BaseSelect from '@/components/common/BaseSelect.vue';
import BaseTextField from '@/components/common/BaseTextField.vue';
import SelectFieldConfiguration from '@/models/input-models/SelectFieldConfiguration';

import { generatePromoCode } from '@/api/promoCodes.api';
import { searchDoctorByName } from '@/api/users.api';

import { getFormattedDate } from '@/utils';

import { VALIDATION_RULES, COUNTRY_SELECT_ITEMS } from '@/constants/common';
import { PROMO_CODES_AMOUNT_TYPE, PROMO_CODES_STATUS } from '@/constants/promo-codes';

const validateDoctorSearchInput = value => {
  if (value && value.name) {
    return true;
  }
  return 'This field is required';
};

const ALL_COUNTRY_OPTION = { value: 'all', text: 'all locations' };
const DEFAULT_START_DATE = new Date().toISOString();

const SELECT_COUNTRY_CONFIG = new SelectFieldConfiguration({
  fieldName: 'country',
  items: [ALL_COUNTRY_OPTION, ...COUNTRY_SELECT_ITEMS]
});

const PROMO_CODE_USERS_CONFIG = [
  { text: 'One doctor', value: true },
  { text: 'All doctors', value: false }
];

const PERECENTAGE_PROMO_CODE_VALUE = '%';

const PROMO_CODE_AMOUNT_TYPES_CONFIG = [
  {
    text: PERECENTAGE_PROMO_CODE_VALUE,
    value: PERECENTAGE_PROMO_CODE_VALUE,
    type: PROMO_CODES_AMOUNT_TYPE.PERCENTAGE
  },
  { text: 'EUR', value: 'EUR', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'CHF', value: 'CHF', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'GBP', value: 'GBP', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'PLN', value: 'PLN', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'DKK', value: 'DKK', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'SEK', value: 'SEK', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'LEV', value: 'LEV', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'CAD', value: 'CAD', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'RON', value: 'RON', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'HUF', value: 'HUF', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'HRK', value: 'HRK', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'AUD', value: 'AUD', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'SGD', value: 'SGD', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'HKD', value: 'HKD', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE },
  { text: 'SAR', value: 'SAR', type: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE }
];

export default {
  name: 'PromoCodeInfoForm',
  promoCodeUsersSelectOptions: PROMO_CODE_USERS_CONFIG,
  amountTypeSelectOptions: PROMO_CODE_AMOUNT_TYPES_CONFIG,
  validationRules: VALIDATION_RULES,
  countriesConfig: SELECT_COUNTRY_CONFIG,
  validateDoctorSearchInput,
  minStartDate: DEFAULT_START_DATE,
  components: { BaseTextField, BaseSelect },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    codeErrors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      searchDoctorName: this.value.doctorName,
      foundDoctors: [],
      isSearchDoctor: false
    };
  },
  computed: {
    currentDoctor() {
      const { doctorName, doctorId } = this.value;

      return { name: doctorName, id: doctorId };
    },
    promoCodeCurrency() {
      return this.value.amountType === PROMO_CODES_AMOUNT_TYPE.PERCENTAGE
        ? PERECENTAGE_PROMO_CODE_VALUE
        : this.value.currency;
    },
    foundDoctorsComputed: {
      get() {
        const shouldDisplayCurrentDoctorOnly = this.value.doctorName && !this.isSearchDoctor;

        return shouldDisplayCurrentDoctorOnly ? [this.currentDoctor] : this.foundDoctors;
      },
      set(doctors) {
        this.foundDoctors = doctors;
      }
    },
    isStartDateFieldDisabled() {
      return (
        this.value.status === PROMO_CODES_STATUS.ACTIVE ||
        this.value.status === PROMO_CODES_STATUS.DEACTIVATED
      );
    },
    selectedCountries() {
      const { countryRestricted, availableCountriesList } = this.value;

      return countryRestricted ? availableCountriesList : [ALL_COUNTRY_OPTION];
    },
    promoCodeUsers() {
      return this.value.doctorRestricted
        ? { text: 'One doctor', value: true }
        : { text: 'All doctors', value: false };
    },
    shouldShowPromoCodeField() {
      return this.value.status !== PROMO_CODES_STATUS.DEACTIVATED;
    },
    shouldShowDoctorField() {
      return this.shouldShowPromoCodeField && this.value.doctorRestricted;
    },
    getMinExpirationDate() {
      const { status, startDate } = this.value;

      const minExpirationDate =
        !this.isEditMode || status === 'INACTIVE' ? startDate : this.$options.minStartDate;

      return addDays(new Date(minExpirationDate), 1).toISOString();
    }
  },
  watch: {
    async searchDoctorName(doctorName) {
      if (doctorName && doctorName.length > 3) {
        this.isSearchDoctor = true;

        const { data } = await searchDoctorByName(doctorName);

        this.foundDoctorsComputed = data;
      }
    }
  },
  methods: {
    selectFoundItemText(item) {
      return item.userName ? `${item.name} (${item.userName})` : `${item.name}`;
    },
    formatDate(date) {
      return getFormattedDate(date);
    },
    getMaxStartDate(expirationDate) {
      return subDays(new Date(expirationDate), 1).toISOString();
    },
    updateDoctorField(value) {
      if (!value) {
        this.$emit('input', { ...this.value, doctorName: null, doctorId: null });
        return;
      }

      const { name, id } = value;

      this.$emit('input', { ...this.value, doctorName: name, doctorId: id });
    },
    updateCountryField(countriesList, { fieldName }) {
      const [firstSelectedAllCountry] = countriesList;
      const lastSelectedAllCountry = last(countriesList);

      const isAllCountriesSelected =
        firstSelectedAllCountry === 'all' || lastSelectedAllCountry === 'all';

      this.$emit('input', {
        ...this.value,
        countryRestricted: !isAllCountriesSelected,
        [fieldName]: isAllCountriesSelected ? [] : countriesList
      });
    },
    updatePromoCodeField(newValue, { fieldName }) {
      this.$emit('input', { ...this.value, [fieldName]: newValue });
    },
    updatePromoCodeCurrencyField(newValue, { fieldName }) {
      if (newValue === PERECENTAGE_PROMO_CODE_VALUE) {
        this.$emit('input', { ...this.value, amountType: PROMO_CODES_AMOUNT_TYPE.PERCENTAGE });

        return;
      }

      this.$emit('input', {
        ...this.value,
        [fieldName]: newValue,
        amountType: PROMO_CODES_AMOUNT_TYPE.ABSOLUTE
      });
    },
    async generateCode() {
      const { data } = await generatePromoCode();

      this.updatePromoCodeField(data, { fieldName: 'code' });
    }
  }
};
</script>

<style lang="scss" scoped>
//TODO: rewrite without v-deep and important

::v-deep {
  .theme--light.v-input--is-disabled input,
  .theme--light.v-select .v-select__selection--disabled {
    color: #324752;
  }

  .theme--light.v-text-field--outlined.v-input--is-disabled fieldset {
    border: none;
  }

  .v-input--is-disabled .v-input__slot {
    padding: 0 !important;
  }

  .v-icon--disabled {
    display: none;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px !important;
  }

  .autocomplete .mdi-menu-down::before {
    content: '' !important;
  }
}

.promo-code-info {
  margin-top: 20px;

  &__amount,
  &__validity {
    display: flex;
    justify-content: space-between;
  }
}

.field {
  margin-bottom: 20px;

  &__label {
    display: flex;
    margin-bottom: 5px;
    color: #94a6b0;
    font-size: 14px;
    line-height: 16px;
  }

  &__input {
    width: 170px;
  }
}

.generate-button-wrapper {
  padding-top: 18px;
}
</style>
