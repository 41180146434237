<template>
  <update-info-modal
    v-model="isModalShown"
    width="420"
    scrollable
    :loading="isLoading"
    modal-title="Promo code"
    :close-button-label="closeButtonLabel"
    action-button-label="Save"
    :should-show-action-button="!isDeactivatedPromoCode"
    :action-button-color="actionButtonColor"
    @action-button-click="onActionButtonClick"
    @close-modal="onModalClose"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <template v-slot:modal-body>
      <div class="promo-code">
        <div class="promo-code__type text-field">
          <span class="promo-code__type-label field-label ">Code type</span>
          <span class="field-value"> {{ promoCodeTypeName }}</span>
        </div>

        <div class="promo-code__status text-field">
          <span class="promo-code__status-label field-label ">Status</span>
          <span class="field-value " :class="$options.codeStatusClasses[promoCode.status]">
            {{ $options.codeStatusValues[promoCode.status] }}</span
          >
        </div>
        <div v-if="isDeactivatedPromoCode" class="promo-code__user text-field">
          <span class="promo-code__user-label field-label">For</span>
          <span class="field-value"> {{ promoCodeUsers }}</span>
        </div>
      </div>
      <v-form ref="promoCodeForm">
        <promo-code-info-form
          :value="promoCode"
          :promo-code-type="promoCodeType"
          :is-edit-mode="true"
          @input="onPromoCodeInfoChange"
        ></promo-code-info-form
      ></v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { clone } from 'ramda';
import { mapGetters } from 'vuex';

import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';
import PromoCodeInfoForm from '@/components/promo-codes/PromoCodeInfoForm.vue';

import { updatePromoCode } from '@/api/promoCodes.api';

import types from '@/store/types';

import { DEFAULT_BUTTON_COLOR } from '@/constants/common';
import {
  PROMO_CODE_TYPES,
  PROMO_CODES_STATUS,
  PROMO_CODES_STATUS_VALUES,
  PROMO_CODE_STATUS_CLASSES
} from '@/constants/promo-codes';

export default {
  name: 'UpdatePromoCodeModal',
  components: { UpdateInfoModal, PromoCodeInfoForm },
  codeStatusValues: PROMO_CODES_STATUS_VALUES,
  codeStatusClasses: PROMO_CODE_STATUS_CLASSES,
  props: {
    promoCodeType: {
      type: String,
      default: ''
    },
    code: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isModalShown: false,
      isLoading: false,
      promoCode: clone(this.code),
      actionButtonColor: DEFAULT_BUTTON_COLOR
    };
  },
  computed: {
    ...mapGetters({
      userId: types.getters.USER_ID
    }),
    promoCodeTypeName() {
      return PROMO_CODE_TYPES[this.promoCodeType];
    },
    isActivePromoCode() {
      return this.promoCode.status === PROMO_CODES_STATUS.ACTIVE;
    },
    closeButtonLabel() {
      return this.isDeactivatedPromoCode ? 'Close' : 'Cancel';
    },
    isDeactivatedPromoCode() {
      return this.promoCode.status === PROMO_CODES_STATUS.DEACTIVATED;
    },
    promoCodeUsers() {
      return this.promoCode.doctorRestricted ? this.promoCode.doctorName : 'all doctors';
    }
  },
  watch: {
    code() {
      this.promoCode = clone(this.code);
    }
  },
  methods: {
    onModalClose() {
      this.isModalShown = false;

      const { promoCodeForm } = this.$refs;

      this.promoCode = clone(this.code);

      promoCodeForm.resetValidation();
    },
    onPromoCodeInfoChange(promoCodeInfo) {
      this.promoCode = { ...promoCodeInfo };
    },
    async onActionButtonClick() {
      const { promoCodeForm } = this.$refs;

      if (!promoCodeForm.validate()) {
        return;
      }
      const code = { ...this.promoCode, doctorName: null, modifiedById: this.userId };

      await updatePromoCode(code);

      this.$emit('updated-code');

      this.onModalClose();
    }
  }
};
</script>

<style lang="scss" scoped>
.field {
  max-width: 170px;
  width: 100%;

  &-label {
    display: block;
    margin-bottom: 10px;
    color: #94a6b0;
    font-size: 14px;
    line-height: 16px;
  }

  &-value {
    color: #324752;
    font-size: 16px;
    line-height: 19px;
  }
}

.promo-code {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 20px;
  padding-top: 40px;

  &__type,
  &__status {
    width: 170px;
    display: flex;
    flex-direction: column;
  }
}
</style>
