<template>
  <main class="d-flex flex-column fill-height white">
    <table-page-main-header :title="pageConfig.pageTitle">
      <template v-slot:header-actions>
        <div class="d-flex justify-end fill-width">
          <create-promo-code-modal
            :code-type="pageConfig.promoCodeType"
            @create-code="updatePromoCodeList()"
          >
            <template v-slot:activator="{ on }">
              <v-btn tile large color="light-blue darken-4" class="white--text ml-3" v-on="on">
                {{ pageConfig.buttonTitle }}
              </v-btn>
            </template>
          </create-promo-code-modal>
        </div>
      </template>
    </table-page-main-header>
    <section class="flex-grow-1">
      <data-table
        :headers="pageConfig.tableConfig"
        :items="promoCodes"
        :options.sync="options"
        :items-per-page="100"
        :disable-pagination="isLoading"
        :server-items-length="serverItemsLength"
      >
        <template v-slot:item.code="{ item }">
          <update-promo-code-modal
            :code="item"
            :promo-code-type="pageConfig.promoCodeType"
            @updated-code="updatePromoCodeList()"
          >
            <template v-slot:activator="{ on }">
              <div
                class="light-blue--text text--darken-4 font-weight-medium cursor-pointer"
                v-on="on"
              >
                {{ item.code }}
              </div>
            </template>
          </update-promo-code-modal>
        </template>

        <template v-slot:item.generated="{ item }">
          <div class="d-flex align-center ">
            {{ formatDate(item.creationDate) }}
          </div>
        </template>

        <template v-slot:item.generatedBy="{ item }">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-avatar :size="14" class="avatar">
                <img src="/icons/user-avatar-icon.svg" alt="User avatar" />
              </v-avatar>
              <router-link
                class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
                :to="{ name: 'UserDetails', params: { id: item.createdById } }"
              >
                {{ item.createdByName }}
              </router-link>
            </div>
          </div>
        </template>

        <template v-slot:item.amount="{ item }">
          <div>
            {{ formatAmountField(item.amount, item.amountType, item.currency) }}
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <div class="d-flex align-center justify-space-between">
            <div :class="$options.codeStatusClasses[item.status]">
              {{ $options.codeStatusValues[item.status] }}
            </div>
            <div
              v-if="item.status !== 'DEACTIVATED'"
              class="red--text font-weight-medium text-decoration-underline cursor-pointer"
              @click="deactivateCode(item.id)"
            >
              Deactivate
            </div>
            <div v-if="item.status === 'DEACTIVATED'" class="grey--text">
              {{ formatDate(item.deactivationDate) }}
            </div>
          </div>
        </template>

        <template v-slot:item.order="{ item: { orders: [order] } }">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-avatar :size="20" class="avatar">
                <img src="/icons/order-icon.svg" />
              </v-avatar>
              <div
                v-if="order"
                class="light-blue--text text--darken-4 font-weight-medium cursor-pointer"
              >
                <router-link
                  class="light-blue--text text--darken-4 font-weight-medium text-decoration-none cursor-pointer"
                  :to="{ name: 'OrderDetails', params: { id: order } }"
                >
                  {{ order }}
                </router-link>
              </div>
              <div v-else>-</div>
            </div>
          </div>
        </template>

        <template v-slot:item.orders="{ item }">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-avatar :size="20" class="avatar">
                <img src="/icons/order-icon.svg" />
              </v-avatar>
              <div
                v-if="item.orders.length"
                class="light-blue--text text--darken-4 font-weight-medium cursor-pointer"
              >
                <router-link
                  class="light-blue--text text--darken-4 font-weight-medium text-decoration-none cursor-pointer"
                  :to="{ name: 'Orders', query: { promoCodeFilter: item.code } }"
                >
                  {{ formatOrderField(item.orders) }}
                </router-link>
              </div>

              <div v-else>-</div>
            </div>
          </div>
        </template>

        <template v-slot:item.doctorName="{ item }">
          <div class="d-flex align-center">
            <div v-if="item.doctorRestricted">
              <v-avatar :size="14" class="avatar">
                <img src="/icons/user-avatar-icon.svg" alt="User avatar" />
              </v-avatar>
              <router-link
                class="light-blue--text text--darken-4 font-weight-medium text-decoration-none cursor-pointer"
                :to="{ name: 'UserDetails', params: { id: item.doctorId } }"
              >
                {{ item.doctorName }}
              </router-link>
            </div>
            <div v-else>All doctors</div>
          </div>
        </template>
      </data-table>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import DataTable from '@/components/common/DataTable.vue';
import TablePageMainHeader from '@/components/common/TablePageMainHeader.vue';
import CreatePromoCodeModal from '@/components/promo-codes/CreatePromoCodeModal.vue';
import UpdatePromoCodeModal from '@/components/promo-codes/UpdatePromoCodeModal.vue';

import { deactivatePromoCode, getPromoCodesList } from '@/api/promoCodes.api';

import {
  getFormattedDate,
  transformDataTableOptionsForRequest,
  formatPromoCodeAmount,
  formatPromoCodeOrderField
} from '@/utils';

import { SORTING_DIRECTIONS } from '@/constants/common';
import { PROMO_CODES_STATUS_VALUES, PROMO_CODE_STATUS_CLASSES } from '@/constants/promo-codes';

import types from '@/store/types';

const FIELD_NAMES = {
  CODE: 'code',
  GENERATED: 'generated',
  GENERATED_BY: 'generatedBy',
  AMOUNT: 'amount',
  STATUS: 'status',
  ORDER: 'order',
  DOCTOR: 'doctorName'
};

const FIELD_NAMES_FOR_PARAMS = {
  [FIELD_NAMES.GENERATED]: 'creationDate',
  [FIELD_NAMES.GENERATED_BY]: 'createdByName',
  [FIELD_NAMES.ORDER]: 'orders'
};

const DEFAULT_SORT_BY_FIELD = FIELD_NAMES.GENERATED;

export default {
  name: 'PromoCodePageTemplate',
  components: { TablePageMainHeader, DataTable, CreatePromoCodeModal, UpdatePromoCodeModal },
  codeStatusValues: PROMO_CODES_STATUS_VALUES,
  codeStatusClasses: PROMO_CODE_STATUS_CLASSES,
  props: {
    pageConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoading: false,
      options: {},
      promoCodes: [],
      serverItemsLength: 0
    };
  },
  computed: {
    ...mapGetters({
      userId: types.getters.USER_ID
    })
  },
  watch: {
    async options() {
      this.promoCodes = await this.getPromoCodes();
    }
  },
  methods: {
    formatAmountField(amount, amountType, currency) {
      return formatPromoCodeAmount(amount, amountType, currency);
    },
    formatOrderField(order) {
      return formatPromoCodeOrderField(order);
    },
    formatDate(date) {
      return getFormattedDate(date);
    },
    async updatePromoCodeList() {
      this.promoCodes = await this.getPromoCodes();
    },
    async deactivateCode(codeId) {
      await deactivatePromoCode(codeId, this.userId);

      await this.updatePromoCodeList();
    },
    async getPromoCodes() {
      try {
        this.isLoading = true;

        const {
          page,
          sortDirection,
          sortType = DEFAULT_SORT_BY_FIELD,
          size
        } = transformDataTableOptionsForRequest(this.options, SORTING_DIRECTIONS.DESC);

        const requestParams = {
          searchQuery: null,
          searchColumnName: null,
          sortColumnName: FIELD_NAMES_FOR_PARAMS[sortType],
          sortDirection,
          page,
          size,
          promoCodeType: this.pageConfig.promoCodeType
        };

        const {
          data: { promoCodes, promoCodesTotal }
        } = await getPromoCodesList(requestParams);

        this.serverItemsLength = promoCodesTotal;

        return promoCodes;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.avatar {
  margin-right: 10px;
}
</style>
