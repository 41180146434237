import httpClient from '@/api/httpClient';

const getPromoCodesList = params => httpClient.post('api/promoCodes/findPaged', params);

const generatePromoCode = () => httpClient.get('api/promoCodes/generateCode');

const createPromoCode = promoCode => httpClient.post('api/promoCodes/create', promoCode);

const deactivatePromoCode = (promoCodeId, modifiedBy) =>
  httpClient.post(`api/promoCodes/deactivate/${promoCodeId}/${modifiedBy}`);

const updatePromoCode = promoCode => httpClient.patch('api/promoCodes/update', promoCode);

export {
  getPromoCodesList,
  generatePromoCode,
  createPromoCode,
  deactivatePromoCode,
  updatePromoCode
};
