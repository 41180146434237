<template>
  <update-info-modal
    v-model="isModalShown"
    width="420"
    scrollable
    :loading="isLoading"
    :modal-title="modalTitle"
    action-button-label="Create"
    :action-button-color="actionButtonColor"
    @action-button-click="onActionButtonClick"
    @close-modal="onModalClose"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <template v-slot:modal-body>
      <v-form ref="promoCodeForm">
        <promo-code-info-form
          :value="promoCodeInfo"
          :code-errors="codeErrors"
          @input="onPromoCodeInfoChange"
        ></promo-code-info-form>
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { addMonths } from 'date-fns';
import { path } from 'ramda';

import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';
import PromoCodeInfoForm from '@/components/promo-codes/PromoCodeInfoForm.vue';

import { createPromoCode } from '@/api/promoCodes.api';

import types from '@/store/types';

import { DEFAULT_BUTTON_COLOR } from '@/constants/common';
import { PROMO_CODE_TYPES } from '@/constants/promo-codes';

const START_DATE = new Date().toISOString();
const EXPIRATION_DATE = addMonths(new Date(), 2).toISOString();

const ERROR_FIELD = {
  PROMO_CODE_EXISTS: 'PROMO_CODE_ALREADY_EXISTS',
  PROMO_CODE_LENGTH: 'INCORRECT_PROMO_CODE_LENGTH',
  PROMO_CODE_INCLUDES_INVALID_CHARACTERS: 'PROMO_CODE_INCLUDES_INVALID_CHARACTERS',
  INVALID_PERCENTAGE_VALUE: 'INVALID_PERCENTAGE_VALUE',
  INVALID_PROMO_CODE_AMOUNT_VALUE: 'INVALID_PROMO_CODE_AMOUNT_VALUE'
};

const CODE_FIELD_ERROR_MESSAGE = {
  [ERROR_FIELD.PROMO_CODE_EXISTS]: 'Invalid promocode',
  [ERROR_FIELD.PROMO_CODE_LENGTH]: 'Invalid promocode',
  [ERROR_FIELD.PROMO_CODE_INCLUDES_INVALID_CHARACTERS]: 'Invalid promocode'
};

const AMOUNT_FIELD_ERROR_MESSAGE = {
  [ERROR_FIELD.INVALID_PERCENTAGE_VALUE]: 'Invalid amount',
  [ERROR_FIELD.INVALID_PROMO_CODE_AMOUNT_VALUE]: 'Invalid amount'
};

const PROMO_CODE_INFO_DEFAULT_FIELDS = {
  amount: null,
  code: null,
  amountType: 'ABSOLUTE',
  currency: 'EUR',
  availableCountriesList: [],
  deactivationDate: null,
  startDate: START_DATE,
  expirationDate: EXPIRATION_DATE,
  doctorId: null,
  doctorName: null,
  id: null,
  orders: [],
  countryRestricted: false,
  doctorRestricted: true,
  createdById: null,
  status: null
};

export default {
  name: 'CreatePromoCodeModal',
  components: { UpdateInfoModal, PromoCodeInfoForm },
  props: {
    codeType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isModalShown: false,
      isLoading: false,
      codeErrors: {},
      promoCodeInfo: { ...PROMO_CODE_INFO_DEFAULT_FIELDS },
      actionButtonColor: DEFAULT_BUTTON_COLOR
    };
  },
  computed: {
    ...mapGetters({
      userId: types.getters.USER_ID
    }),
    modalTitle() {
      const status = PROMO_CODE_TYPES[this.codeType].toLowerCase();

      return `New ${status} promo code`;
    }
  },
  methods: {
    onModalClose() {
      this.isModalShown = false;

      const { promoCodeForm } = this.$refs;

      promoCodeForm.resetValidation();

      this.promoCodeInfo = { ...PROMO_CODE_INFO_DEFAULT_FIELDS };
      this.codeErrors = {};
    },
    onPromoCodeInfoChange(promoCodeInfo) {
      this.promoCodeInfo = { ...promoCodeInfo };
    },
    async onActionButtonClick() {
      this.codeErrors = {};
      const { promoCodeForm } = this.$refs;

      if (!promoCodeForm.validate()) {
        return;
      }
      const promoCode = {
        ...this.promoCodeInfo,
        type: this.codeType,
        createdById: this.userId
      };

      try {
        await createPromoCode(promoCode);

        this.onModalClose();

        this.$emit('create-code');
      } catch (error) {
        const requestError = path(['response', 'data', 'description'], error);

        if (CODE_FIELD_ERROR_MESSAGE[requestError]) {
          this.codeErrors = { code: CODE_FIELD_ERROR_MESSAGE[requestError] };
        }

        if (AMOUNT_FIELD_ERROR_MESSAGE[requestError]) {
          this.codeErrors = { amount: AMOUNT_FIELD_ERROR_MESSAGE[requestError] };
        }
      }
    }
  }
};
</script>
