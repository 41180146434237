var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"d-flex flex-column fill-height white"},[_c('table-page-main-header',{attrs:{"title":_vm.pageConfig.pageTitle},scopedSlots:_vm._u([{key:"header-actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-end fill-width"},[_c('create-promo-code-modal',{attrs:{"code-type":_vm.pageConfig.promoCodeType},on:{"create-code":function($event){return _vm.updatePromoCodeList()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text ml-3",attrs:{"tile":"","large":"","color":"light-blue darken-4"}},on),[_vm._v(" "+_vm._s(_vm.pageConfig.buttonTitle)+" ")])]}}])})],1)]},proxy:true}])}),_c('section',{staticClass:"flex-grow-1"},[_c('data-table',{attrs:{"headers":_vm.pageConfig.tableConfig,"items":_vm.promoCodes,"options":_vm.options,"items-per-page":100,"disable-pagination":_vm.isLoading,"server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('update-promo-code-modal',{attrs:{"code":item,"promo-code-type":_vm.pageConfig.promoCodeType},on:{"updated-code":function($event){return _vm.updatePromoCodeList()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"light-blue--text text--darken-4 font-weight-medium cursor-pointer"},on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)})]}},{key:"item.generated",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center "},[_vm._v(" "+_vm._s(_vm.formatDate(item.creationDate))+" ")])]}},{key:"item.generatedBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"avatar",attrs:{"size":14}},[_c('img',{attrs:{"src":"/icons/user-avatar-icon.svg","alt":"User avatar"}})]),_c('router-link',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none",attrs:{"to":{ name: 'UserDetails', params: { id: item.createdById } }}},[_vm._v(" "+_vm._s(item.createdByName)+" ")])],1)])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatAmountField(item.amount, item.amountType, item.currency))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{class:_vm.$options.codeStatusClasses[item.status]},[_vm._v(" "+_vm._s(_vm.$options.codeStatusValues[item.status])+" ")]),(item.status !== 'DEACTIVATED')?_c('div',{staticClass:"red--text font-weight-medium text-decoration-underline cursor-pointer",on:{"click":function($event){return _vm.deactivateCode(item.id)}}},[_vm._v(" Deactivate ")]):_vm._e(),(item.status === 'DEACTIVATED')?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.formatDate(item.deactivationDate))+" ")]):_vm._e()])]}},{key:"item.order",fn:function(ref){
var order = ref.item.orders[0];
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"avatar",attrs:{"size":20}},[_c('img',{attrs:{"src":"/icons/order-icon.svg"}})]),(order)?_c('div',{staticClass:"light-blue--text text--darken-4 font-weight-medium cursor-pointer"},[_c('router-link',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none cursor-pointer",attrs:{"to":{ name: 'OrderDetails', params: { id: order } }}},[_vm._v(" "+_vm._s(order)+" ")])],1):_c('div',[_vm._v("-")])],1)])]}},{key:"item.orders",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"avatar",attrs:{"size":20}},[_c('img',{attrs:{"src":"/icons/order-icon.svg"}})]),(item.orders.length)?_c('div',{staticClass:"light-blue--text text--darken-4 font-weight-medium cursor-pointer"},[_c('router-link',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none cursor-pointer",attrs:{"to":{ name: 'Orders', query: { promoCodeFilter: item.code } }}},[_vm._v(" "+_vm._s(_vm.formatOrderField(item.orders))+" ")])],1):_c('div',[_vm._v("-")])],1)])]}},{key:"item.doctorName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.doctorRestricted)?_c('div',[_c('v-avatar',{staticClass:"avatar",attrs:{"size":14}},[_c('img',{attrs:{"src":"/icons/user-avatar-icon.svg","alt":"User avatar"}})]),_c('router-link',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none cursor-pointer",attrs:{"to":{ name: 'UserDetails', params: { id: item.doctorId } }}},[_vm._v(" "+_vm._s(item.doctorName)+" ")])],1):_c('div',[_vm._v("All doctors")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }